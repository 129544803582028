<template>
  <div id="standardSolution">
    <el-dialog
      :title="standardSolutionFormTitle"
      width="1200px"
      :visible.sync="standardSolutionDialogVisible"
      :close-on-click-modal="false"
      @close="standardSolutionDialogClose"
    >
      <el-form
        ref="standardSolutionFormRef"
        :model="standardSolutionForm"
        :rules="standardSolutionFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="溶液名称" prop="name">
              <el-input v-model="standardSolutionForm.name" placeholder="请输入溶液名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="配制浓度" prop="concentration">
              <el-input v-model="standardSolutionForm.concentration" placeholder="请输入配制浓度" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="所用试剂及仪器设备" prop="reagentEquipment">
              <el-input
                v-model="standardSolutionForm.reagentEquipment"
                placeholder="请输入审核过程综述"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="配制方法" prop="preparationMethod">
              <el-input
                v-model="standardSolutionForm.preparationMethod"
                placeholder="请输入配制方法"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="配制日期" prop="preparationDate">
              <el-date-picker v-model="standardSolutionForm.preparationDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="配制人" prop="preparer">
              <el-input v-model="standardSolutionForm.preparer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="standardSolutionForm.reviewer" placeholder="请输入复核人" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="有效期" prop="validPeriod">
              <el-input v-model="standardSolutionForm.validPeriod" placeholder="请输入有效期" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用途" prop="purpose">
              <el-input v-model="standardSolutionForm.purpose" placeholder="请输入用途" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="standardSolutionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="standardSolutionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="部门">
        <el-input v-model="searchForm.dept" placeholder="请输入部门" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="standardSolutionPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="溶液名称" />
      <el-table-column prop="concentration" label="配制浓度" />
      <el-table-column prop="reagentEquipment" label="所用试剂及仪器设备" />
      <el-table-column prop="preparationMethod" label="配制方法" />
      <el-table-column prop="preparationDate" label="配制日期" />
      <el-table-column prop="preparer" label="配制人" />
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column prop="validPeriod" label="有效期" />
      <el-table-column prop="purpose" label="用途" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="standardSolutionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addStandardSolution, deleteStandardSolution, updateStandardSolution, selectStandardSolutionInfo, selectStandardSolutionList } from '@/api/quality/standardSolutionPreparation'

export default {
  data () {
    return {
      standardSolutionDialogVisible: false,
      standardSolutionFormTitle: '',
      standardSolutionForm: {
        id: '',
        name: '',
        concentration: '',
        reagentEquipment: '',
        preparationMethod: '',
        preparationDate: '',
        preparer: '',
        reviewer: '',
        validPeriod: '',
        purpose: ''
      },
      standardSolutionFormRules: {
        name: [{ required: true, message: '溶液名称不能为空', trigger: ['blur', 'change']}]
      },
      standardSolutionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        dept: ''
      }
    }
  },
  created () {
    selectStandardSolutionList(this.searchForm).then(res => {
      this.standardSolutionPage = res
    })
  },
  methods: {
    standardSolutionDialogClose () {
      this.$refs.standardSolutionFormRef.resetFields()
      this.standardSolutionForm.id = ''
    },
    standardSolutionFormSubmit () {
      if (this.standardSolutionFormTitle === '标准溶液配制详情') {
        this.standardSolutionDialogVisible = false
        return
      }
      this.$refs.standardSolutionFormRef.validate(async valid => {
        if (valid) {
          if (this.standardSolutionFormTitle === '新增标准溶液配制') {
            await addStandardSolution(this.standardSolutionForm)
          } else if (this.standardSolutionFormTitle === '修改标准溶液配制') {
            await updateStandardSolution(this.standardSolutionForm)
          }
          this.standardSolutionPage = await selectStandardSolutionList(this.searchForm)
          this.standardSolutionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.standardSolutionFormTitle = '新增标准溶液配制'
      this.standardSolutionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteStandardSolution(row.id)
        if (this.standardSolutionPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.standardSolutionPage = await selectStandardSolutionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.standardSolutionFormTitle = '修改标准溶液配制'
      this.standardSolutionDialogVisible = true
      this.selectStandardSolutionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.standardSolutionFormTitle = '成品标准溶液配制'
      this.standardSolutionDialogVisible = true
      this.selectStandardSolutionInfoById(row.id)
    },
    selectStandardSolutionInfoById (id) {
      selectStandardSolutionInfo(id).then(res => {
        this.standardSolutionForm.id = res.id
        this.standardSolutionForm.name = res.name
        this.standardSolutionForm.concentration = res.concentration
        this.standardSolutionForm.reagentEquipment = res.reagentEquipment
        this.standardSolutionForm.preparationMethod = res.preparationMethod
        this.standardSolutionForm.preparationDate = res.preparationDate
        this.standardSolutionForm.preparer = res.preparer
        this.standardSolutionForm.reviewer = res.reviewer
        this.standardSolutionForm.validPeriod = res.validPeriod
        this.standardSolutionForm.purpose = res.purpose
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectStandardSolutionList(this.searchForm).then(res => {
        this.standardSolutionPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectStandardSolutionList(this.searchForm).then(res => {
        this.standardSolutionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectStandardSolutionList(this.searchForm).then(res => {
        this.standardSolutionPage = res
      })
    }
  }
}
</script>

<style>
</style>
