import axios from '@/common/axios'
import qs from 'qs'

export function addStandardSolution (data) {
  return axios({
    method: 'post',
    url: '/quality/standardSolution/add',
    data: qs.stringify(data)
  })
}

export function deleteStandardSolution (id) {
  return axios({
    method: 'delete',
    url: '/quality/standardSolution/delete/' + id
  })
}

export function updateStandardSolution (data) {
  return axios({
    method: 'put',
    url: '/quality/standardSolution/update',
    data: qs.stringify(data)
  })
}

export function selectStandardSolutionInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/standardSolution/info/' + id
  })
}

export function selectStandardSolutionList (query) {
  return axios({
    method: 'get',
    url: '/quality/standardSolution/list',
    params: query
  })
}
